import { Navigate } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

export interface GuestGuardProps {
    children: React.ReactNode;
}

export const GuestGuard = ({ children }: GuestGuardProps) => {
    const { user } = useAuth();

    if (user) {
        return <Navigate to='/app' />;
    }

    return <>{children}</>;
};
