import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { supabase } from '../lib/supabase';
import { Organization } from '../types/Organization';

export type OrganizationContextType = {
    organizations: Organization[];
    currentOrganization: Organization | null;
    changeCurrentOrganization: (organizationId: string) => void;
};

export const OrganizationsContext = createContext<OrganizationContextType>({
    organizations: [],
    currentOrganization: null,
    changeCurrentOrganization: (_organizationId: string) => {
        throw new Error('Not implemented');
    },
});

export interface OrganizationContextProps {
    children: React.ReactNode;
}

export const OrganizationsProvider = ({ children }: OrganizationContextProps) => {
    const { userData, updateUserData } = useAuth();

    const navigate = useNavigate();

    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);

    const fetchOrganizations = useCallback(async () => {
        const { data, error } = await supabase
            .from<Organization>('organizations')
            .select(
                `
                id,
                name,
                legal_name,
                created_at,
                extensions(*),
                members:organization_members(
                    id,
                    role,
                    created_at,
                    user:users(*)
                )
            `
            )
            .order('name');
        if (data) {
            setOrganizations(data);
            if (data.length) {
                setCurrentOrganization(data.find((o) => o.id === userData?.current_organization) ?? data[0]);
            }
        } else {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }, [userData?.current_organization]);

    const changeCurrentOrganization = useCallback(
        (organizationId: string) => {
            navigate('/app/dashboard');
            setCurrentOrganization(organizations.find((o) => o.id === organizationId) ?? null);
            updateUserData({ current_organization: organizationId });
        },
        [navigate, organizations, updateUserData]
    );

    useEffect(() => {
        if (userData) {
            fetchOrganizations();
        }
    }, [fetchOrganizations, userData]);

    return (
        <OrganizationsContext.Provider
            value={{
                organizations,
                currentOrganization,
                changeCurrentOrganization,
            }}
        >
            {children}
        </OrganizationsContext.Provider>
    );
};
