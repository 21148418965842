import { Box, Divider, Drawer, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { NavbarMenuItem } from '../../types/NavMenuItem';
import { useOrganizations } from '../../hooks/useOrganizations';

import { DashboardSidebarSection } from './DasboardSidebarSection';

export interface DashboardSidebarProps {
    onPin: () => void;
    pinned: boolean;
}

export const DashboardSidebar = ({ onPin, pinned }: DashboardSidebarProps) => {
    const { t, i18n } = useTranslation();

    const { currentOrganization } = useOrganizations();

    const primaryItems: NavbarMenuItem[] = [
        {
            href: '/app/dashboard',
            icon: 'dashboard',
            label: t('common.dashboard'),
        },
    ];

    currentOrganization?.extensions
        .sort((a, b) => a.label[i18n.language].localeCompare(b.label[i18n.language]))
        .map((extension) =>
            primaryItems.push({
                href: `/app/extension/${extension.id}`,
                icon: extension.icon,
                label: extension.label[i18n.language],
            })
        );

    const secondaryItems: NavbarMenuItem[] = [
        {
            href: '/app/organization',
            icon: 'business',
            label: t('common.organization'),
        },
        {
            href: '/app/account',
            icon: 'settings',
            label: t('common.account'),
        },
    ];

    return (
        <Drawer
            open
            sx={{ zIndex: 1000 }}
            variant='permanent'
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 64px)',
                    overflowX: 'hidden',
                    p: 1,
                    top: 64,
                    transition: 'width 250ms ease-in-out',
                    width: pinned ? 295 : 73,
                    '&:hover': {
                        width: 295,
                        '& span': {
                            display: 'block',
                        },
                    },
                },
            }}
        >
            <DashboardSidebarSection items={primaryItems} pinned={pinned} />
            <Box sx={{ flexGrow: 1 }} />
            <DashboardSidebarSection items={secondaryItems} pinned={pinned} />
            <Divider />
            <Box sx={{ p: 1 }}>
                <IconButton onClick={onPin}>{pinned ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}</IconButton>
            </Box>
        </Drawer>
    );
};
