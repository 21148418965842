import { Provider, Session, User } from '@supabase/supabase-js';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import { supabase } from '../lib/supabase';
import { UserData } from '../types/UserData';

export type AuthContextType = {
    session: Session | null;
    user: User | null;
    userData: UserData | null;
    signInWithMagicLink: (email: string) => Promise<{
        session: Session | null;
        user: User | null;
        provider?: Provider | undefined;
        url?: string | null | undefined;
        error: Error | null;
        data: Session | null;
    }>;
    signInWithPassword: (
        email: string,
        password: string
    ) => Promise<{
        user: User | null;
        session: Session | null;
        error: Error | null;
        data: Session | User | null;
    }>;
    signUpWithPassword: (
        email: string,
        password: string
    ) => Promise<{
        user: User | null;
        session: Session | null;
        error: Error | null;
        data: Session | User | null;
    }>;
    signOut: () => Promise<{
        error: Error | null;
    }>;
    fetchUserDataByEmail: (email: string) => Promise<UserData | null>;
    updateUserData: (newUserData: Partial<UserData>) => Promise<UserData | null>;
};

export const AuthContext = createContext<AuthContextType>({
    session: null,
    user: null,
    userData: null,
    signInWithMagicLink: () => Promise.reject(new Error('Not implemented')),
    signInWithPassword: () => Promise.reject(new Error('Not implemented')),
    signUpWithPassword: () => Promise.reject(new Error('Not implemented')),
    signOut: supabase.auth.signOut,
    fetchUserDataByEmail: () => Promise.reject(new Error('Not implemented')),
    updateUserData: () => Promise.reject(new Error('Not implemented')),
});

export interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [session, setSession] = useState<Session | null>(null);
    const [userData, setUserData] = useState<UserData | null>(null);

    const fetchUserDataByEmail = async (email: string) => {
        const res = await supabase.from<UserData>('users').select('*').eq('email', email);
        return res.data?.[0] || null;
    };

    const fetchAndStoreCurrentUserData = useCallback(async () => {
        if (session && session.user && session.user.email) {
            setUserData(await fetchUserDataByEmail(session.user.email));
        } else {
            setUserData(null);
        }
    }, [session]);

    const signInWithMagicLink = async (email: string) => {
        return await supabase.auth.signIn({ email }, { redirectTo: `${location.protocol}//${location.host}/app/dashboard` });
    };

    const signInWithPassword = async (email: string, password: string) => {
        return await supabase.auth.signIn({ email, password });
    };

    const signUpWithPassword = async (email: string, password: string) => {
        return await supabase.auth.signUp({ email, password });
    };

    const updateUserData = useCallback(
        async (newUserData: Partial<UserData>) => {
            if (userData) {
                const { data } = await supabase.from<UserData>('users').update(newUserData).eq('id', userData?.id).single();
                setUserData(data);
                return data;
            }
            return null;
        },
        [userData]
    );

    useEffect(() => {
        setSession(supabase.auth.session());
        fetchAndStoreCurrentUserData();

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            fetchAndStoreCurrentUserData();
        });
    }, [fetchAndStoreCurrentUserData]);

    return (
        <AuthContext.Provider
            value={{
                session,
                user: session?.user ?? null,
                userData,
                signInWithMagicLink,
                signInWithPassword,
                signUpWithPassword,
                signOut: supabase.auth.signOut,
                fetchUserDataByEmail,
                updateUserData,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
