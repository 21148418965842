import { Box, ButtonBase, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';

import { usePopover } from '../../../hooks/usePopover';
import { useOrganizations } from '../../../hooks/useOrganizations';

export const OrganizationPopover = () => {
    const { organizations, currentOrganization, changeCurrentOrganization } = useOrganizations();
    const [anchorRef, open, handleOpen, handleClose] = usePopover();

    const handleOrganizationChange = (organizationId: string) => {
        handleClose();
        changeCurrentOrganization(organizationId);
    };

    // Don't show the popover if they only have one organization
    if (organizations.length === 1) {
        return null;
    }

    return (
        <>
            <ButtonBase
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    borderRadius: 1,
                    p: 1,
                    width: 180,
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.1)',
                    },
                    display: {
                        md: 'flex',
                        xs: 'none',
                    },
                }}
            >
                <Typography
                    color='textSecondary'
                    sx={{
                        color: 'primary.contrastText',
                        mr: 3,
                    }}
                    variant='subtitle2'
                >
                    {currentOrganization?.name}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <UnfoldMore fontSize='small' />
            </ButtonBase>
            <Popover
                anchorEl={anchorRef?.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 200 },
                }}
            >
                <List>
                    {organizations.map((organization) => (
                        <ListItem
                            key={organization.id}
                            button
                            selected={organization.id === currentOrganization?.id}
                            onClick={() => handleOrganizationChange(organization.id)}
                        >
                            <ListItemText primary={organization.name} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    );
};
