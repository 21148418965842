import { Box, Button, Card, Container, Grid, Typography } from '@mui/material';

const tech = [
    {
        name: 'react',
        icon: '/static/react.svg',
    },
    {
        name: 'typescript',
        icon: '/static/typescript.svg',
    },
    {
        name: 'figma',
        icon: '/static/figma.svg',
    },
];

export const Support = () => (
    <Box sx={{ pt: 15 }}>
        <Container maxWidth='lg'>
            <Card elevation={0} sx={{ backgroundColor: 'neutral.100' }}>
                <Grid
                    container
                    sx={{
                        pb: {
                            md: 6,
                            xs: 3,
                        },
                        pt: {
                            md: 8,
                            xs: 3,
                        },
                        px: {
                            md: 8,
                            xs: 3,
                        },
                    }}
                >
                    <Grid
                        item
                        md={6}
                        sx={{
                            borderRight: (theme) => ({
                                md: `1px solid ${theme.palette.divider}`,
                            }),
                            display: 'flex',
                            flexDirection: 'column',
                            mb: {
                                md: 0,
                                xs: 4,
                            },
                            pr: {
                                md: 4,
                            },
                        }}
                        xs={12}
                    >
                        <Typography color='textPrimary' variant='h4'>
                            Lorem Ipsum
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='subtitle1'
                            sx={{
                                mb: 4,
                                mt: 1,
                            }}
                        >
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                            aliquyam erat, sed diam voluptua.
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button color='primary' component='a' href='/' rel='nofollow noreferrer noopener' target='_blank' variant='text'>
                                Lorem ipsum
                            </Button>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box
                                sx={{
                                    '& img:not(:last-child)': {
                                        mr: 3,
                                    },
                                }}
                            >
                                {tech.map((item) => (
                                    <img alt={item.name} key={item.name} src={item.icon} />
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            pl: {
                                md: 4,
                            },
                        }}
                        xs={12}
                    >
                        <Typography color='textPrimary' variant='h4'>
                            Premium Support
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='subtitle1'
                            sx={{
                                mb: 4,
                                mt: 1,
                            }}
                        >
                            Our support team is here to help you get started with any questions. We answer pretty fast.
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button color='primary' component='a' href='/' target='_blank' variant='text'>
                                Contact us
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    </Box>
);
