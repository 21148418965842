import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useAuth } from './hooks/useAuth';
import { routes } from './routes';
import { createCustomTheme } from './theme';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
        },
    },
});

export const App = () => {
    const { i18n } = useTranslation();
    const content = useRoutes(routes);
    const { userData } = useAuth();

    const theme = createCustomTheme({
        theme: userData?.theme ?? 'light',
    });

    useEffect(() => {
        i18n.changeLanguage(userData?.language ?? 'en');
    }, [i18n, userData?.language]);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {content}
            </ThemeProvider>
        </QueryClientProvider>
    );
};
