import PropTypes from 'prop-types';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';

const FaqCard = (props) => {
    const { question, children } = props;

    return (
        <Card elevation={0} sx={{ backgroundColor: 'neutral.100' }}>
            <CardContent>
                <Typography align='left' color='textPrimary' sx={{ mb: 2 }} variant='h6'>
                    {question}
                </Typography>
                <Typography align='left' color='textSecondary' variant='body2'>
                    {children}
                </Typography>
            </CardContent>
        </Card>
    );
};

FaqCard.propTypes = {
    children: PropTypes.node,
    question: PropTypes.string.isRequired,
};

export const Faqs = () => (
    <Box
        sx={{
            pb: 9,
            pt: 15,
        }}
    >
        <Container maxWidth='lg'>
            <Typography align='center' color='textPrimary' sx={{ mb: 8 }} variant='h2'>
                Frequently asked questions
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridAutoFlow: {
                        md: 'column',
                        xs: 'row',
                    },
                    gap: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        height: 'fit-content',
                        gridAutoFlow: 'row',
                        gap: 3,
                    }}
                >
                    <FaqCard question='Lorem ipsum dolor sit amet, consetetur sadipscing elitr?'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </FaqCard>
                    <FaqCard question='How many projects can I build with Carpatin - Retail &amp; Back Office?'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </FaqCard>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        height: 'fit-content',
                        gridAutoFlow: 'row',
                        gap: 3,
                    }}
                >
                    <FaqCard question='Lorem ipsum dolor sit amet, consetetur sadipscing elitr?'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </FaqCard>
                    <FaqCard question='Lorem ipsum dolor sit amet, consetetur sadipscing elitr?'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </FaqCard>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        height: 'fit-content',
                        gridAutoFlow: 'row',
                        gap: 3,
                    }}
                >
                    <FaqCard question='Lorem ipsum dolor sit amet, consetetur sadipscing elitr?'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </FaqCard>
                    <FaqCard question='Lorem ipsum dolor sit amet, consetetur sadipscing elitr?'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    </FaqCard>
                </Box>
            </Box>
        </Container>
    </Box>
);
