import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const Hero = () => (
    <Box
        sx={{
            backgroundColor: 'neutral.100',
            pt: 8,
        }}
    >
        <Container maxWidth='lg'>
            <Typography align='center' color='textPrimary' variant='h1'>
                AREZ CMS
                <br />
                Dream it. Create it. Publish it.
            </Typography>
            <Typography
                align='center'
                color='textSecondary'
                sx={{
                    fontSize: 22,
                    mb: 5,
                    mt: 2,
                }}
            >
                Build a website, publish posts, send newsletters, grow an audience, sell premium subscriptions, create a sustainable business around
                your creative work. AREZ does it all, and so much more.
            </Typography>
            <Grid container justifyContent='center' spacing={2} sx={{ mb: 6 }} wrap='wrap'>
                <Grid item>
                    <Button color='secondary' component='a' href='/register' size='large' target='_blank' variant='text'>
                        Sign Up
                    </Button>
                </Grid>
                <Grid item>
                    <Button color='secondary' component={RouterLink} size='large' to='/app' variant='contained'>
                        Start Creating
                    </Button>
                </Grid>
            </Grid>
            <img
                alt='hero'
                src='/static/hero.png'
                style={{
                    display: 'block',
                    maxWidth: '100%',
                }}
            />
        </Container>
    </Box>
);
