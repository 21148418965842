import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { NavbarMenuItem } from '../../types/NavMenuItem';

export interface DashboardSidebarSectionProps {
    items: NavbarMenuItem[];
    pinned: boolean;
}

export const DashboardSidebarSection = ({ items = [], pinned }: DashboardSidebarSectionProps) => {
    const location = useLocation();

    return (
        <List sx={{ width: '100%' }}>
            {items.map(({ label, href, icon }) => {
                const active = matchPath({ path: href, end: false }, location.pathname);

                return (
                    <ListItem
                        disablePadding
                        component={RouterLink}
                        key={href}
                        to={href}
                        sx={{
                            color: active ? 'primary.main' : 'text.secondary',
                            px: 2,
                            py: 1.5,
                            '&:hover': {
                                color: 'primary.main',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 'auto',
                                color: 'inherit',
                            }}
                        >
                            <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={label}
                            primaryTypographyProps={{
                                variant: 'subtitle2',
                                sx: {
                                    display: pinned ? 'block' : 'none',
                                    ml: 1,
                                },
                            }}
                            sx={{ my: 0 }}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};
