import React, { Fragment } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { Divider, Drawer, Icon, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NavbarMenuItem } from '../../types/NavMenuItem';
import { useOrganizations } from '../../hooks/useOrganizations';

export interface DashboardNavbarMenuProps {
    open: boolean;
    onClose: () => void;
}

export const DashboardNavbarMenu = ({ open, onClose }: DashboardNavbarMenuProps) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const { currentOrganization } = useOrganizations();

    const items: NavbarMenuItem[] = [
        {
            href: '/app/dashboard',
            icon: 'dashboard',
            label: t('common.dashboard'),
        },
    ];

    currentOrganization?.extensions
        .sort((a, b) => a.label[i18n.language].localeCompare(b.label[i18n.language]))
        .map((extension) =>
            items.push({
                href: `/app/extension/${extension.id}`,
                icon: extension.icon,
                label: extension.label[i18n.language],
            })
        );

    return (
        <Drawer
            anchor='top'
            onClose={onClose}
            open={open}
            transitionDuration={0}
            ModalProps={{
                BackdropProps: {
                    invisible: true,
                },
            }}
            PaperProps={{
                sx: {
                    backgroundColor: '#2B2F3C',
                    color: '#B2B7C8',
                    display: 'flex',
                    flexDirection: 'column',
                    top: 64,
                    width: '100vw',
                },
            }}
        >
            <List>
                {items.map(({ label, href, icon }, index) => {
                    const active = matchPath({ path: href, end: false }, location.pathname);

                    return (
                        <Fragment key={href}>
                            <ListItemButton
                                component={RouterLink}
                                onClick={onClose}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    px: 3,
                                    py: 1.5,
                                    ...(active && {
                                        backgroundColor: '#383D4D',
                                        color: '#ffffff',
                                    }),
                                    '&:hover': {
                                        backgroundColor: '#383D4D',
                                        color: '#ffffff',
                                    },
                                }}
                                to={href}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: 'inherit',
                                        minWidth: 0,
                                        mr: 1,
                                    }}
                                >
                                    <Icon>{icon}</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={label}
                                    primaryTypographyProps={{
                                        color: 'inherit',
                                        variant: 'caption',
                                    }}
                                />
                            </ListItemButton>
                            {items.length > index + 1 && <Divider sx={{ borderColor: '#3F455A' }} />}
                        </Fragment>
                    );
                })}
            </List>
        </Drawer>
    );
};
