import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Container, IconButton, List } from '@mui/material';
import { Menu } from '@mui/icons-material';

import { lightNeutral } from '../colors';

import { MainNavbarLink } from './main-navbar-link';

export const MainNavbar = (props) => {
    const { onOpenSidebar } = props;

    return (
        <AppBar elevation={0} sx={{ backgroundColor: lightNeutral[900] }}>
            <Container
                maxWidth='lg'
                sx={{
                    alignItems: 'center',
                    color: '#ffffff',
                    display: 'flex',
                    height: 64,
                    position: 'relative',
                }}
            >
                <RouterLink to='/'>
                    <img src='/logo.svg' alt='AREZ Logo' height={42} />
                </RouterLink>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    color='inherit'
                    onClick={onOpenSidebar}
                    sx={{
                        display: {
                            md: 'none',
                        },
                    }}
                >
                    <Menu fontSize='small' />
                </IconButton>
                <List
                    disablePadding
                    sx={{
                        display: {
                            md: 'flex',
                            xs: 'none',
                        },
                        position: 'static',
                    }}
                >
                    <MainNavbarLink component={RouterLink} label='Sign Up' to='/register' />
                    <MainNavbarLink component={RouterLink} label='Login' to='/app' />
                    <MainNavbarLink component={RouterLink} label='Docs' to='/docs' />
                </List>
            </Container>
        </AppBar>
    );
};

MainNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};
