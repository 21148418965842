import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Card, CardContent, Container, Grid, Toolbar } from '@mui/material';

import { ProductFeatures } from '../components/auth/ProductFeatures';
import { LoginForm } from '../components/auth/LoginForm';

export const Login = () => {
    return (
        <>
            <Helmet>
                <title>Login | AREZ</title>
            </Helmet>
            <AppBar elevation={0} sx={{ backgroundColor: 'background.paper' }}>
                <Container maxWidth='lg'>
                    <Toolbar disableGutters sx={{ height: 64 }}>
                        <RouterLink to='/'>
                            <img src='/logo.svg' alt='AREZ Logo' height={42} />
                        </RouterLink>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    pt: '96px',
                }}
            >
                <Box sx={{ py: 9 }}>
                    <Container maxWidth='lg'>
                        <Grid container spacing={6}>
                            <Grid
                                item
                                md={6}
                                sx={{
                                    display: {
                                        md: 'block',
                                        xs: 'none',
                                    },
                                }}
                                xs={12}
                            >
                                <ProductFeatures />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Card sx={{ backgroundColor: 'background.default' }} elevation={0}>
                                    <CardContent>
                                        <LoginForm />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
};
