import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Divider, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { KeyboardArrowDown, Brightness4, Brightness7 } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks/useAuth';
import { NotificationsPopover } from '../notifications-popover';
import { LanguagePopover } from '../LanguagePopover';

import { AccountPopover } from './account/AccountPopover';
import { OrganizationPopover } from './organization/OrganizationPopover';
import { DashboardNavbarMenu } from './DashboardNavbarMenu';

export const DashboardNavbar = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { userData, updateUserData } = useAuth();

    const [openMenu, setOpenMenu] = useState(false);

    const handleSwitchTheme = () => {
        updateUserData({ theme: userData?.theme === 'dark' ? 'light' : 'dark' });
    };

    return (
        <AppBar elevation={0} sx={{ backgroundColor: '#1e212a' }}>
            <Toolbar
                disableGutters
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    minHeight: 64,
                    px: 3,
                    py: 1,
                }}
            >
                <Box
                    component={RouterLink}
                    to='/'
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img src='/logo.svg' alt='AREZ Logo' height={42} />
                </Box>
                <Divider
                    flexItem
                    orientation='vertical'
                    sx={{
                        borderColor: 'rgba(255,255,255,0.1)',
                        mx: 3,
                    }}
                />
                <OrganizationPopover />
                <DashboardNavbarMenu onClose={() => setOpenMenu(false)} open={mdDown && openMenu} />
                <Button
                    endIcon={
                        <KeyboardArrowDown
                            fontSize='small'
                            sx={{
                                ml: 2,
                                transition: 'transform 250ms',
                                transform: openMenu ? 'rotate(180deg)' : 'none',
                            }}
                        />
                    }
                    onClick={() => setOpenMenu(true)}
                    sx={{
                        color: 'primary.contrastText',
                        display: {
                            md: 'none',
                            xs: 'flex',
                        },
                    }}
                    variant='text'
                >
                    {t('common.menu')}
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <LanguagePopover
                    sx={{
                        display: {
                            md: 'inline-flex',
                            xs: 'none',
                        },
                    }}
                />
                <IconButton
                    color='inherit'
                    onClick={handleSwitchTheme}
                    sx={{
                        display: {
                            md: 'inline-flex',
                            xs: 'none',
                        },
                        mx: 1,
                    }}
                >
                    {userData?.theme === 'light' ? <Brightness7 /> : <Brightness4 />}
                </IconButton>
                <NotificationsPopover />
                <AccountPopover />
            </Toolbar>
        </AppBar>
    );
};
