import 'simplebar/dist/simplebar.min.css';
import 'nprogress/nprogress.css';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AuthProvider } from './contexts/AuthContext';
import { App } from './App';
import { OrganizationsProvider } from './contexts/OrganizationContext';
import './i18n';
import { LoadingScreen } from './components/LoadingScreen';

ReactDOM.render(
    <StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <AuthProvider>
                        <OrganizationsProvider>
                            <Suspense fallback={<LoadingScreen />}>
                                <App />
                            </Suspense>
                            <Toaster position='bottom-right' />
                        </OrganizationsProvider>
                    </AuthProvider>
                </LocalizationProvider>
            </BrowserRouter>
        </HelmetProvider>
    </StrictMode>,
    document.getElementById('root') as HTMLElement
);
