import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Footer } from '../Footer';
import { useAuth } from '../../hooks/useAuth';

import { DashboardNavbar } from './DashboardNavbar';
import { DashboardSidebar } from './DashboardSidebar';

export const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
}));

export const DashboardLayoutContentWrapper = styled('div')(() => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '64px',
}));

export const DashboardLayoutContent = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: 'auto',
});

export const DashboardLayout = () => {
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { userData, updateUserData } = useAuth();

    const handlePinSidebar = () => {
        updateUserData({
            pinned_sidebar: !userData?.pinned_sidebar,
        });
    };

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar />
            {!mdDown && <DashboardSidebar onPin={handlePinSidebar} pinned={userData?.pinned_sidebar ?? true} />}
            <DashboardLayoutContentWrapper
                sx={{
                    pl: {
                        md: userData?.pinned_sidebar ?? true ? '295px' : '73px',
                    },
                }}
            >
                <DashboardLayoutContent>
                    <Outlet />
                    <Footer />
                </DashboardLayoutContent>
            </DashboardLayoutContentWrapper>
        </DashboardLayoutRoot>
    );
};
