import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, Drawer, IconButton, List } from '@mui/material';
import { Cancel, KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';

import { lightNeutral } from '../colors';

import { MainSidebarLink } from './main-sidebar-link';

const MainSidebarSection = (props) => {
    const { section } = props;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <li>
                <Button
                    color='inherit'
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex',
                        width: '100%',
                    }}
                    variant='text'
                    onClick={handleClick}
                >
                    {section.title}
                    {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </Button>
            </li>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List disablePadding sx={{ color: 'neutral.500' }}>
                    {section.items.map((item) => (
                        <MainSidebarLink key={item.title} label={item.title} sx={{ pl: 3 }} component={RouterLink} to={item.href} />
                    ))}
                </List>
            </Collapse>
        </>
    );
};

MainSidebarSection.propTypes = {
    section: PropTypes.object.isRequired,
};

export const MainSidebar = (props) => {
    const { onClose, open } = props;
    const location = useLocation();

    useEffect(() => {
        if (open && onClose) {
            onClose();
        }
    }, [location.pathname]);

    return (
        <Drawer
            anchor='right'
            onClose={onClose}
            open={open}
            variant='temporary'
            PaperProps={{
                sx: {
                    backgroundColor: lightNeutral[900],
                    color: 'primary.contrastText',
                    width: 256,
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: 2,
                    px: 3,
                }}
            >
                <IconButton onClick={onClose} sx={{ color: '#ffffff' }}>
                    <Cancel />
                </IconButton>
            </Box>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    p: 2,
                }}
            >
                <List sx={{ width: '100%' }}>
                    <MainSidebarLink component={RouterLink} to='/app' label='Login' />
                    <MainSidebarLink component={RouterLink} to='/docs' label='Docs' />
                </List>
            </Box>
        </Drawer>
    );
};

MainSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
