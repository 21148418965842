import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Faqs } from '../components/home/faqs';
import { Features } from '../components/home/Features';
import { Hero } from '../components/home/hero';
import { Pricing } from '../components/home/Pricing';
import { Support } from '../components/home/support';
import { UserFlows } from '../components/home/user-flows';
import { useAuth } from '../hooks/useAuth';

const companies = [
    {
        icon: '/static/company-bolt.svg',
        name: 'bolt',
    },
    {
        icon: '/static/company-samsung.svg',
        name: 'samsung',
    },
    {
        icon: '/static/company-ford.svg',
        name: 'ford',
    },
    {
        icon: '/static/company-bd.svg',
        name: 'bd',
    },
    {
        icon: '/static/company-mbank.svg',
        name: 'mbank',
    },
    {
        icon: '/static/company-lockheed_martin.svg',
        name: 'lockheed martin',
    },
];

export const Home = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/app');
        }
    }, [navigate, user]);

    return (
        <>
            <Helmet>
                <title>Home | AREZ</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default' }}>
                <Hero />
                <Support />
                <Features />
                <UserFlows />
                <Pricing />
                <Faqs />
                <Container
                    maxWidth='md'
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        pb: 15,
                    }}
                >
                    <Typography color='textSecondary' component='p' sx={{ mb: 3 }} variant='overline'>
                        Some of our customers
                    </Typography>
                    <Grid container spacing={2} wrap='wrap' justifyContent='center'>
                        {companies.map((company) => (
                            <Grid item key={company.name}>
                                <img alt={company.name} src={company.icon} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};
