import { useState, useCallback } from 'react';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { InputField } from '../common/fields/InputField';
import { useAuth } from '../../hooks/useAuth';

export const LoginForm = () => {
    const { t } = useTranslation();
    const { signInWithMagicLink, signInWithPassword, signUpWithPassword, fetchUserDataByEmail } = useAuth();
    const { control, handleSubmit, getValues } = useForm();

    const [isSignUp, setIsSignUp] = useState(false);
    const [isSignInWithPassword, setIsSignInWithPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [error, setError] = useState({ message: '' });

    const handleSignIn = useCallback(async () => {
        setIsLoading(true);
        const email = getValues('email');
        const password = getValues('password');

        const user = await fetchUserDataByEmail(email);

        // check if the user exists
        if (user) {
            setFirstName(user.first_name || '');
            if (isSignInWithPassword) {
                const { data, error } = await signInWithPassword(email, password);
                if (error) {
                    setError(error);
                } else {
                    console.log(data);
                }
            } else {
                // sign in with magic link
                await signInWithMagicLink(email);
                setIsEmailSent(true);
                toast.success(t('common.emailSent'));
            }
        } else {
            // show sign up form
            setIsSignUp(true);
        }
        setIsLoading(false);
    }, [fetchUserDataByEmail, getValues, isSignInWithPassword, signInWithMagicLink, signInWithPassword, t]);

    const handleSignUp = useCallback(async () => {
        const email = getValues('email');
        const password = getValues('createPassword');

        const { data, error } = await signUpWithPassword(email, password);
        if (error) {
            toast.error(error.message);
        }
        setIsLoading(false);
        console.log(data);
    }, [getValues, signUpWithPassword]);

    const handleChangeForm = useCallback(async () => {
        if (isSignUp) {
            setIsSignUp(false);
            setIsSignInWithPassword(false);
        } else {
            setIsSignInWithPassword((prevState) => !prevState);
            setIsSignUp(false);
        }
        setIsLoading(false);
        setIsEmailSent(false);
        setError({ message: '' });
    }, [isSignUp]);

    const handleForgotPassword = useCallback(async () => {
        toast.success(t('login.forgotPasswordMessage'), { duration: 10000 });
    }, [t]);

    return (
        <form onSubmit={handleSubmit(isSignUp ? handleSignUp : handleSignIn)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color='textPrimary' variant='h4'>
                        {isSignUp ? t('common.signUp') : t('common.signIn')}
                    </Typography>
                </Grid>
                {isSignUp && (
                    <Grid item xs={12}>
                        <Alert severity='info'>{t('login.userNotFound')}</Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Controller
                        name='email'
                        control={control}
                        defaultValue=''
                        rules={{ required: t('validation.required').toString() }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <InputField
                                variant='filled'
                                type='email'
                                label={t('login.yourEmail')}
                                onChange={onChange}
                                value={value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled={isSignUp || isLoading}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                {isSignInWithPassword && !isSignUp && (
                    <Grid item xs={12}>
                        <Controller
                            name='password'
                            control={control}
                            defaultValue=''
                            rules={{ required: t('validation.required').toString() }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputField
                                    variant='filled'
                                    type='password'
                                    label={t('login.yourPassword')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    fullWidth
                                />
                            )}
                        />
                        <Button onClick={handleForgotPassword}>{t('login.forgotPassword')}</Button>
                    </Grid>
                )}
                {isSignUp && (
                    <Grid item xs={12}>
                        <Controller
                            name='createPassword'
                            control={control}
                            defaultValue=''
                            rules={{
                                required: t('validation.required').toString(),
                                minLength: {
                                    value: 8,
                                    message: t('validation.minLength', { minLength: 8 }),
                                },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputField
                                    variant='filled'
                                    type='password'
                                    label={t('login.passwordCreate')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button color='primary' fullWidth size='large' type='submit' variant='contained' disabled={isLoading}>
                        {isSignUp ? t('common.signUp') : isSignInWithPassword ? t('login.signInWithPassword') : t('login.signInWithMagicLink')}
                    </Button>
                </Grid>
                {!isSignInWithPassword && !isSignUp && isEmailSent && (
                    <Grid item xs={12}>
                        <Alert>{t('login.checkEmail', { firstName })}</Alert>
                    </Grid>
                )}
                {error.message && (
                    <Grid item xs={12}>
                        <Alert severity='error'>{error.message}</Alert>
                    </Grid>
                )}
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button onClick={handleChangeForm}>
                        {!isSignUp && isSignInWithPassword && t('login.signInWithMagicLink')}
                        {!isSignUp && !isSignInWithPassword && t('login.signInWithPassword')}
                        {isSignUp && t('login.signInWithAnotherAccount')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
