import React, { useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { MainNavbar } from '../main-navbar';
import { MainSidebar } from '../main-sidebar';
import { Footer } from '../Footer';

const MainLayoutRoot = styled('div')(() => ({
    paddingTop: 64,
}));

export interface MainLayoutProps {
    children?: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const lgDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));

    return (
        <MainLayoutRoot>
            <MainNavbar onOpenSidebar={() => setOpenSidebar(true)} />
            <MainSidebar onClose={() => setOpenSidebar(false)} open={lgDown && openSidebar} />
            {children}
            <Footer />
        </MainLayoutRoot>
    );
};
