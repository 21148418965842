import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const features = [
    'Authentication Services',
    'app Reports',
    'Advanced Filters',
    'Customers Management',
    'Products Management',
    'Inventory Management',
];

export const ProductFeatures = () => (
    <>
        <Typography color='textPrimary' variant='h4'>
            AREZ - Content, newsletters, members, payments — all in one place
        </Typography>
        <Typography
            color='textSecondary'
            sx={{
                mb: 3,
                mt: 1,
            }}
            variant='body2'
        >
            Build a website, publish posts, send newsletters, grow an audience, sell premium subscriptions,create a sustainable business around your
            creative work.
        </Typography>
        <List sx={{ py: 2 }}>
            {features.map((feature) => (
                <ListItem disableGutters key={feature}>
                    <ListItemIcon
                        sx={{
                            minWidth: 'auto',
                            mr: 1,
                        }}
                    >
                        <CheckCircle sx={{ color: 'success.main' }} />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                </ListItem>
            ))}
        </List>
    </>
);
