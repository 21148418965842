import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { Account } from './containers/account/Account';
import { AuthGuard } from './components/auth/AuthGuard';
import { GuestGuard } from './components/auth/GuestGuard';
import { LoadingScreen } from './components/LoadingScreen';
import { Organization } from './containers/organization/Organization';
import { DashboardLayout } from './components/app/DashboardLayout';
import { MainLayout } from './components/home/MainLayout';
import { Home } from './containers/Home';

export const Loadable = (Component: React.LazyExoticComponent<() => JSX.Element>) =>
    function LoadableComponent(props: JSX.IntrinsicAttributes) {
        return (
            <Suspense fallback={<LoadingScreen />}>
                <Component {...props} />
            </Suspense>
        );
    };

// Auth pages
const Login = Loadable(lazy(() => import('./containers/Login').then((module) => ({ default: module.Login }))));

// Dashboard pages
const Dashboard = Loadable(lazy(() => import('./containers/Dashboard').then((module) => ({ default: module.Dashboard }))));

const AccountGeneral = Loadable(lazy(() => import('./containers/account/AccountGeneral').then((module) => ({ default: module.AccountGeneral }))));
const AccountNotifications = Loadable(
    lazy(() => import('./containers/account/AccountNotifications').then((module) => ({ default: module.AccountNotifications })))
);

const OrganizationBilling = Loadable(
    lazy(() => import('./containers/organization/OrganizationBilling').then((module) => ({ default: module.OrganizationBilling })))
);
const OrganizationGeneral = Loadable(
    lazy(() => import('./containers/organization/OrganizationGeneral').then((module) => ({ default: module.OrganizationGeneral })))
);
const OrganizationTeam = Loadable(
    lazy(() => import('./containers/organization/OrganizationTeam').then((module) => ({ default: module.OrganizationTeam })))
);

const Extension = Loadable(lazy(() => import('./containers/Extension').then((module) => ({ default: module.ExtensionView }))));
const Entity = Loadable(lazy(() => import('./containers/entitiy/Entity').then((module) => ({ default: module.EntityView }))));

// Docs pages
const Docs = Loadable(lazy(() => import('./containers/Docs').then((module) => ({ default: module.Docs }))));

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/NotFound').then((module) => ({ default: module.NotFound }))));

export const routes = [
    {
        path: '/',
        element: (
            <MainLayout>
                <Home />
            </MainLayout>
        ),
    },
    {
        path: 'login',
        element: (
            <GuestGuard>
                <Login />
            </GuestGuard>
        ),
    },
    {
        path: 'app',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to='/app/dashboard' replace />,
            },
            {
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                path: 'extension',
                children: [
                    {
                        path: ':extensionId',
                        children: [
                            {
                                path: '/',
                                element: <Extension />,
                            },
                            {
                                path: ':entityId',
                                element: <Entity />,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'account',
                element: <Account />,
                children: [
                    {
                        path: '/',
                        element: <AccountGeneral />,
                    },
                    {
                        path: 'notifications',
                        element: <AccountNotifications />,
                    },
                    {
                        path: 'team',
                        element: <OrganizationTeam />,
                    },
                ],
            },
            {
                path: 'organization',
                element: <Organization />,
                children: [
                    {
                        path: '/',
                        element: <OrganizationGeneral />,
                    },
                    {
                        path: '/team',
                        element: <OrganizationTeam />,
                    },
                    {
                        path: '/billing',
                        element: <OrganizationBilling />,
                    },
                ],
            },
        ],
    },
    {
        path: 'docs',
        children: [
            {
                path: '/',
                element: <Navigate to='/docs/overview/welcome' replace />,
            },
            {
                path: '*',
                element: <Docs />,
            },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
];
