import React, { useRef, useState, useMemo } from 'react';
import { Box, IconButton, IconButtonProps, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../hooks/useAuth';

const languages = [
    {
        key: 'en',
        icon: '/static/uk_flag.svg',
    },
    {
        key: 'de',
        icon: '/static/de_flag.svg',
    },
];

export const LanguagePopover = (props: IconButtonProps) => {
    const { t } = useTranslation();
    const { userData, updateUserData } = useAuth();

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLanguageChange = (language: string) => {
        updateUserData({
            language,
        });
        setOpen(false);
    };

    const selectedLanguage = useMemo(() => {
        if (userData) {
            return languages.find((lang) => lang.key === userData.language) ?? languages[0];
        }
        return languages[0];
    }, [userData]);

    return (
        <>
            <IconButton onClick={handleOpen} ref={anchorRef} {...props}>
                <Box
                    sx={{
                        display: 'flex',
                        height: 20,
                        width: 20,
                        '& img': {
                            width: '100%',
                        },
                    }}
                >
                    <img alt={t(`languages.${selectedLanguage.key}`)} src={selectedLanguage.icon} />
                </Box>
            </IconButton>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 },
                }}
            >
                {languages.map((language) => (
                    <MenuItem key={language.key} onClick={() => handleLanguageChange(language.key)}>
                        <ListItemIcon>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 20,
                                    width: 20,
                                    '& img': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <img alt={t(`languages.${language.key}`)} src={language.icon} />
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography color='textPrimary' variant='subtitle2'>
                                    {t(`languages.${language.key}`)}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
};
