import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Account = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const tabs = [
        {
            href: '/app/account',
            label: t('common.general'),
        },
        {
            href: '/app/account/notifications',
            label: t('common.notifications'),
        },
    ];

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                flexGrow: 1,
            }}
        >
            <Container
                maxWidth='lg'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box sx={{ py: 4 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <Typography color='textPrimary' variant='h4'>
                            {t('account.settings')}
                        </Typography>
                    </Box>
                    <Tabs allowScrollButtonsMobile value={tabs.findIndex((tab) => tab.href === location.pathname)} sx={{ mt: 2 }}>
                        {tabs.map((option) => (
                            <Tab component={RouterLink} key={option.href} label={option.label} to={option.href} />
                        ))}
                    </Tabs>
                    <Divider />
                </Box>
                <Outlet />
            </Container>
        </Box>
    );
};
