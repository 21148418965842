import { Box, Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation();

    const links: any[] = [
        /*{
            label: t('footer.docs'),
            href: '#',
        },
        {
            label: t('footer.terms'),
            href: '#',
        },
        {
            label: t('footer.privacy'),
            href: '#',
        },*/
    ];

    return (
        <Box sx={{ backgroundColor: 'background.default' }}>
            <Container
                maxWidth='xl'
                sx={{
                    display: 'flex',
                    flexDirection: {
                        md: 'row',
                        xs: 'column',
                    },
                    py: 3,
                    '& a': {
                        mt: {
                            md: 0,
                            xs: 1,
                        },
                        '&:not(:last-child)': {
                            mr: {
                                md: 5,
                                xs: 0,
                            },
                        },
                    },
                }}
            >
                <Typography color='textSecondary' variant='caption'>
                    © {new Date().getFullYear()} AREZ. {t('footer.allRightsReserved')}.
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {links.map((link) => (
                    <Link color='textSecondary' href={link.href} key={link.label} underline='none' variant='body2'>
                        {link.label}
                    </Link>
                ))}
            </Container>
        </Box>
    );
};
