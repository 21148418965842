import React, { ChangeEvent, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Typography, Popover, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, Switch } from '@mui/material';
import { KeyboardArrowDown, ExitToApp, Business, People } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../hooks/useAuth';
import { usePopover } from '../../../hooks/usePopover';
import { lightNeutral } from '../../../colors';
import { InputField } from '../../common/fields/InputField';
import { useOrganizations } from '../../../hooks/useOrganizations';
import { supabase } from '../../../lib/supabase';

const languages = [
    {
        key: 'en',
        icon: '/static/uk_flag.svg',
    },
    {
        key: 'de',
        icon: '/static/de_flag.svg',
    },
];

export const AccountPopover = () => {
    const { t, i18n } = useTranslation();
    const { userData, updateUserData } = useAuth();
    const { organizations, currentOrganization, changeCurrentOrganization } = useOrganizations();
    const [anchorRef, open, handleOpen, handleClose] = usePopover();

    const handleOrganizationChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            changeCurrentOrganization(event.target.value);
        },
        [changeCurrentOrganization]
    );

    const handleSwitchTheme = () => {
        updateUserData({ theme: userData?.theme === 'dark' ? 'light' : 'dark' });
    };

    const handleLanguageChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateUserData({
                language: event.target.value,
            });
        },
        [updateUserData]
    );

    const handleLogout = useCallback(async () => {
        await supabase.auth.signOut();
    }, []);

    return (
        <>
            <Box
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    ml: 2,
                }}
            >
                <Avatar
                    src={userData?.avatar}
                    variant='rounded'
                    sx={{
                        height: 40,
                        width: 40,
                    }}
                />
                <Box
                    sx={{
                        alignItems: 'center',
                        display: {
                            md: 'flex',
                            xs: 'none',
                        },
                        flex: 1,
                        ml: 1,
                        minWidth: 120,
                    }}
                >
                    <div>
                        <Typography
                            variant='caption'
                            sx={{
                                color: lightNeutral[500],
                            }}
                        >
                            {userData?.email}
                        </Typography>
                        <Typography sx={{ color: 'primary.contrastText' }} variant='subtitle2'>
                            {`${userData?.first_name} ${userData?.last_name}`}
                        </Typography>
                    </div>
                    <KeyboardArrowDown
                        sx={{
                            color: 'primary.contrastText',
                            ml: 1,
                        }}
                    />
                </Box>
            </Box>

            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: 260,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                {organizations.length > 1 && (
                    <InputField
                        variant='filled'
                        fullWidth
                        onChange={handleOrganizationChange}
                        select
                        SelectProps={{ native: true }}
                        value={currentOrganization?.id}
                        sx={{
                            display: {
                                md: 'none',
                            },
                            pt: 2,
                            px: 2,
                        }}
                    >
                        {organizations.map((organization) => (
                            <option key={organization.id} value={organization.id}>
                                {organization.name}
                            </option>
                        ))}
                    </InputField>
                )}
                <List>
                    <ListItem divider>
                        <ListItemAvatar>
                            <Avatar variant='rounded' src={userData?.avatar} />
                        </ListItemAvatar>
                        <ListItemText primary={`${userData?.first_name} ${userData?.last_name}`} secondary={userData?.email} />
                    </ListItem>
                    <ListItem
                        sx={{
                            display: {
                                md: 'none',
                                xs: 'flex',
                            },
                        }}
                    >
                        <InputField
                            variant='filled'
                            fullWidth
                            onChange={handleLanguageChange}
                            select
                            SelectProps={{ native: true }}
                            value={i18n.language}
                        >
                            {languages.map((lang) => (
                                <option key={lang.key} value={lang.key}>
                                    {t(`languages.${lang.key}`)}
                                </option>
                            ))}
                        </InputField>
                    </ListItem>
                    <ListItem
                        sx={{
                            py: 0,
                            display: {
                                md: 'none',
                                xs: 'flex',
                            },
                        }}
                    >
                        <Switch checked={userData?.theme === 'dark'} onChange={handleSwitchTheme} />
                        <Typography color='textPrimary' variant='body2'>
                            {t('common.darkMode')}
                        </Typography>
                    </ListItem>
                    <ListItem button component={RouterLink} divider onClick={handleClose} to='/app/organization'>
                        <ListItemIcon>
                            <Business sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary={t('common.organization')} />
                    </ListItem>
                    <ListItem button component={RouterLink} divider onClick={handleClose} to='/app/account'>
                        <ListItemIcon>
                            <People sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary={t('common.account')} />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <ExitToApp sx={{ color: 'text.primary' }} />
                        </ListItemIcon>
                        <ListItemText primary={t('common.logOut')} />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};
